import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { axisoReqWithAuthToken } from "../Utils";
import ToggleComponent from "./generalComponents/ToggleComponent";
import BetComponent from "./generalComponents/BetComponent";
import CancelBetComponent from "./generalComponents/CancelBetComponent";
import SettleUpComponent from "./adminComponents/SettleUpComponent";

export default function CreateCard({
    matchData,
    isAdmin,
    isExpired,
    balanceAndExposure,
    setBalanceAndExposure,
}) {
    const [enableToggle, setEnableToggle] = useState(!matchData.active);
    const [bettingTeam, setBettingTeam] = useState(null);
    const [potentialBettingTeam, setPotentialBettingTeam] = useState(null);
    const [betAmount, setBetAmount] = useState(0);
    const [showBetCard, setShowBetCard] = useState(false);
    const [isBetPlaced, setIsBetPlaced] = useState(false);
    const [isCancelBtnClicked, setIsCancelBtnClicked] = useState(false);
    const [showSettleupCard, setShowSettleupCard] = useState(false);
    const [isCancelBetResponseLoading, setIsCancelBetResponseLoading] =
        useState(false);

    const navigate = useNavigate();

    let posterData = null,
        posterContentType = null;
    if (matchData.posterImg) {
        posterData = matchData.posterImg.data;
        posterContentType = matchData.posterImg.contentType;
    }

    const getWalletDetails = async () => {
        try {
            const { data } = await axisoReqWithAuthToken.get("/wallet");
            setBalanceAndExposure({
                balance: data.balance,
                exposure: data.exposure,
            });
        } catch (error) {
            toast.error("Failed to update balance and exposure", {
                className: "toast-message",
            });
        }
    };

    const betBtnHandler = async (e) => {
        e.preventDefault();
        if (bettingTeam && bettingTeam !== e.target.value) {
            toast.error(
                <div className="text-center">
                    To bet on team {e.target.value},
                    <br /> Please cancel your bet on team {bettingTeam}
                </div>,
                {
                    className: "toast-message",
                }
            );
            return;
        }
        setPotentialBettingTeam(e.target.value);
        setShowBetCard(true);
    };

    const cancelBetHandler = async (e) => {
        e.preventDefault();
        try {
            setIsCancelBetResponseLoading(true);
            const { data } = await axisoReqWithAuthToken.post(
                "/client/cancelBet",
                { betId: matchData._id }
            );
            setBettingTeam(null);
            setPotentialBettingTeam(null);
            setBetAmount(0);
            setIsBetPlaced(false);
            setIsCancelBtnClicked(false);
            toast.success(data.message, { className: "toast-message" });
            getWalletDetails();
            setIsCancelBetResponseLoading(false);
        } catch (error) {
            setIsCancelBetResponseLoading(false);
            setIsCancelBtnClicked(false);
            toast.error(error.response.data.message, {
                className: "toast-message",
            });
            if (error.response.status === 401) {
                localStorage.clear();
                navigate("/login");
            }
        }
    };

    const getBetAmount = async () => {
        const { data } = await axisoReqWithAuthToken.get(
            `/client/getOutStandingAmount?betId=${matchData._id}`
        );
        if (data.totalOutStandingAmount) {
            setBetAmount(data.totalOutStandingAmount);
            setBettingTeam(data.bettingTeam);
            setIsBetPlaced(true);
        }
    };

    useEffect(() => {
        getBetAmount();
    }, []);

    return (
        <div>
            {isCancelBtnClicked && (
                <CancelBetComponent
                    setIsCancelBtnClicked={setIsCancelBtnClicked}
                    cancelBetHandler={cancelBetHandler}
                    bettingTeam={bettingTeam}
                    betAmount={betAmount}
                    isCancelBetResponseLoading={isCancelBetResponseLoading}
                />
            )}
            {showBetCard && (
                <BetComponent
                    betId={matchData._id}
                    potentialBettingTeam={potentialBettingTeam}
                    setPotentialBettingTeam={setPotentialBettingTeam}
                    bettingTeam={bettingTeam}
                    setBettingTeam={setBettingTeam}
                    betAmount={betAmount}
                    setBetAmount={setBetAmount}
                    setShowBetCard={setShowBetCard}
                    isBetPlaced={isBetPlaced}
                    setIsBetPlaced={setIsBetPlaced}
                    balanceAndExposure={balanceAndExposure}
                    rate={matchData.tossRate}
                    getWalletDetails={getWalletDetails}
                />
            )}
            {isAdmin && showSettleupCard && (
                <SettleUpComponent
                    setShowSettleupCard={setShowSettleupCard}
                    matchData={matchData}
                />
            )}
            <div
                className={
                    `${isAdmin && enableToggle ? "bg-gray-400" : "bg-white"} ` +
                    "w-full h-fit py-7 flex flex-col items-center justify-center gap-3 px-8 rounded-xl drop-shadow-[0_10px_15px_rgba(0,0,0,0.1)] transform transition duration-700 ease-in-out hover:scale-105 hover:shadow-2xl overflow-hidden"
                }
            >
                {isAdmin && !isExpired && (
                    <ToggleComponent
                        enableToggle={enableToggle}
                        setEnableToggle={setEnableToggle}
                        betId={matchData._id}
                    />
                )}
                <div>
                    <div className="flex flex-col items-center justify-center gap-3 min-w-[17rem] w-fit max-w-[18rem] min-h-[22rem]">
                        <p className="text-xl font-bold">
                            End Time: {matchData?.betEndTime}
                        </p>
                        <p className="text-gray-500 font-semibold">
                            Date: {matchData?.betEndDate}
                        </p>
                        <p className="text-lg text-red-500 font-bold">
                            Toss Rate: {matchData?.tossRate}
                        </p>
                        {posterData ? (
                            <img
                                src={`data:${posterContentType};base64,${btoa(
                                    String.fromCharCode(
                                        ...new Uint8Array(posterData.data)
                                    )
                                )}`}
                                alt={`${matchData.teamAName} VS ${matchData.teamBName} poster`}
                                className="w-10 h-10 posterTransition"
                            />
                        ) : (
                            <div className="w-10 h-10"></div>
                        )}
                        <span className="text-xl font-bold capitalize">
                            {matchData?.leagueName}
                        </span>
                        <div className="w-full flex items-center justify-between font-semibold">
                            <p className="text-center text-xl font-bold justify-self-start">
                                {matchData?.teamAName}
                                {isBetPlaced && (
                                    <span
                                        className={
                                            "block text-[14px] " +
                                            `${
                                                bettingTeam ===
                                                matchData.teamAName
                                                    ? "text-green-700"
                                                    : "text-red-600"
                                            }`
                                        }
                                    >
                                        {bettingTeam === matchData.teamAName
                                            ? `+${(
                                                  (1 +
                                                      +matchData.tossRate /
                                                          100) *
                                                  betAmount
                                              ).toFixed(2)}`
                                            : `-${betAmount}`}
                                    </span>
                                )}
                                {isAdmin &&
                                    matchData.teamATotalAmount !== null &&
                                    matchData.teamBTotalAmount !== null && (
                                        <span
                                            className={
                                                "block text-[14px] " +
                                                `${
                                                    matchData.teamATotalAmount >
                                                    matchData.teamBTotalAmount
                                                        ? "text-red-700"
                                                        : "text-green-600"
                                                }`
                                            }
                                        >
                                            {!matchData.teamATotalAmount
                                                ? "0"
                                                : (matchData.teamATotalAmount >
                                                  matchData.teamBTotalAmount
                                                      ? "-"
                                                      : "+") +
                                                  ` ${matchData.teamATotalAmount}`}
                                        </span>
                                    )}
                            </p>
                            <span className="px-4">VS</span>
                            <p className="text-center text-xl font-bold justify-self-end">
                                {matchData?.teamBName}
                                {isBetPlaced && (
                                    <span
                                        className={
                                            "block text-[14px] " +
                                            `${
                                                bettingTeam ===
                                                matchData.teamBName
                                                    ? "text-green-700"
                                                    : "text-red-600"
                                            }`
                                        }
                                    >
                                        {bettingTeam === matchData.teamBName
                                            ? `+${(
                                                  (1 +
                                                      +matchData.tossRate /
                                                          100) *
                                                  betAmount
                                              ).toFixed(2)}`
                                            : `-${betAmount}`}
                                    </span>
                                )}
                                {isAdmin &&
                                    matchData.teamATotalAmount !== null &&
                                    matchData.teamBTotalAmount !== null && (
                                        <span
                                            className={
                                                "block text-[14px] " +
                                                `${
                                                    matchData.teamBTotalAmount >
                                                    matchData.teamATotalAmount
                                                        ? "text-red-700"
                                                        : "text-green-600"
                                                }`
                                            }
                                        >
                                            {!matchData.teamBTotalAmount
                                                ? "0"
                                                : (matchData.teamBTotalAmount >
                                                  matchData.teamATotalAmount
                                                      ? "-"
                                                      : "+") +
                                                  ` ${matchData.teamBTotalAmount}`}
                                        </span>
                                    )}
                            </p>
                        </div>
                        <div className="w-full flex items-center justify-between font-semibold gap-2 mb-2">
                            {isAdmin && (
                                <NavLink
                                    to="/admin/editBet"
                                    state={matchData}
                                    className="p-1 pl-2 pr-2 rounded-md border-red-500 border-solid border-2 hover:bg-red-500 hover:text-white transition-li"
                                >
                                    <span className="font-semibold">
                                        Edit Bet
                                    </span>
                                </NavLink>
                            )}
                            {!isAdmin && (
                                <button
                                    value={matchData?.teamAName}
                                    className="hover:bg-red-500 text-red-700 text-center font-semibold hover:text-white py-1 px-5 border border-red-500 hover:border-transparent rounded transition-btn"
                                    onClick={betBtnHandler}
                                >
                                    Bet
                                </button>
                            )}
                            {isAdmin && (
                                <button
                                    className="p-1 pl-2 pr-2 rounded-md border-red-500 hover:border-red-600 border-solid border-2 hover:bg-red-500 hover:text-white transition-li font-semibold"
                                    onClick={() => setShowSettleupCard(true)}
                                >
                                    Settle Up
                                </button>
                            )}
                            {!isAdmin && (
                                <button
                                    value={matchData?.teamBName}
                                    className="hover:bg-red-500 text-red-700 text-center font-semibold hover:text-white py-1 px-5 border border-red-500 hover:border-transparent rounded transition-btn"
                                    onClick={betBtnHandler}
                                >
                                    Bet
                                </button>
                            )}
                        </div>
                    </div>
                    {isBetPlaced && (
                        <>
                            <button
                                className="w-5/6 mx-auto px-2 py-1 hover:bg-red-500 text-red-700 text-center font-semibold hover:text-white border border-red-500 hover:border-transparent rounded transition-btn"
                                onClick={() => setIsCancelBtnClicked(true)}
                            >
                                Cancel
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
