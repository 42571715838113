import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import { useParams } from "react-router";
import CreateTable from "../CreateTable";
import { getActivePageData, axisoReqWithAuthToken, getTimeAMPMFormat } from "../../Utils";
import PaginationComponent from "../generalComponents/PaginationComponent";
import { toast } from "react-toastify";

export default function UserAccountStatementComponent() {
  const { username } = useParams();

  const [userAccountStatement, setUserAccountStatement] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(0);

  const navigate = useNavigate();

  const entriesPerPage = 7;
  const entryStart = activePage * entriesPerPage;
  const entryEnd = (activePage + 1) * entriesPerPage;

  const columns = [
    { header: "No", field: "no" },
    { header: "Date", field: "date" },
    {header: "Description", field: "description"},
    {header: "Bet Team", field: "team"},
    { header: "Transaction Type", field: "transactionType" },
    { header: "Amount", field: "amount" },
    {header: "Balance", field: "balance"},
  ];

  const getUserAccountStatement = async () => {
    try {
      setIsLoading(true);
      setUserAccountStatement([]);
      const {data} = await axisoReqWithAuthToken.get(`/client/getPassbookDetails?userName=${username}`);
      data.passbookDetails.forEach((element, idx) => {
        const timestamp = new Date(element.timeStamp);
        setUserAccountStatement(prev => [...prev, {no: idx+1, date: `${timestamp.getDate().toString().padStart(2, "0")}-${(timestamp.getMonth()+1).toString().padStart(2, "0")}-${timestamp.getFullYear()} ${getTimeAMPMFormat(timestamp)}`,description: element.transactionDescription, transactionType: element.transactionType,team: element.betTeamName, amount: element.transactionAmount, balance: element.balance}]);
      });
      setIsLoading(false);
    } catch (error) {
        setIsLoading(false);
        toast.error(error.response.data.message, { className: "toast-message" });
        if(error.response.status===401) {
          localStorage.clear();
          navigate("/login");
        }
    }
  };

  useEffect(() => {
    getUserAccountStatement();
  }, []);

  return (
    <div className="w-full bg-white mt-8  my-4 p-4 h-fit overflow-hidden p-2 rounded-xl">
      <CreateTable
        columns={columns}
        data={getActivePageData(userAccountStatement, entryStart, entryEnd)}
        tableName={`Account Statement Report for ${username}`}
      />
      <PaginationComponent
        numberOfEntries={userAccountStatement.length}
        entriesPerPage={entriesPerPage}
        activePage={activePage}
        setActivePage={setActivePage}
      />
    </div>
  );
}
