import React, {useState, useEffect} from "react";
import { toast } from "react-toastify";
import {axisoReqWithAuthToken, delay} from "../Utils";

export default function Wallet({ balanceAndExposure, setBalanceAndExposure }) {
  const [isLoading, setIsLoading] = useState(false);
  const whatsappInviteLink = "https://wa.me/+918484848422";
  const getWalletDetails = async () => {
    try {
      setIsLoading(true);
      const { data } = await axisoReqWithAuthToken.get(`/wallet`);
      setBalanceAndExposure({ balance: data.balance || 0, exposure: data.exposure || 0});
      setIsLoading(false);
        } catch (error) {
      setIsLoading(false);

      toast.error("Failed to update balance and exposure", {
        className: "toast-message",
      });
    }
  };

  useEffect(() => {
    getWalletDetails();
  }, []);

  return (
    !isLoading && (
      <div className="z-40 flex justify-between items-center bg-red-500 h-10 px-6 font-semibold max-sm:px-0">
        <p className="text-white  p-4 py-8">
          Balance: {balanceAndExposure.balance}
        </p>
        <a 
          href={whatsappInviteLink} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="border-2 border-white text-white rounded-full p-1 cursor-pointer  mx-4"
        >
          D/W
        </a>
        <p className="text-white  px-4 py-10">
          Exp: {balanceAndExposure.exposure}
        </p>
      </div>
    )
  );
}
