import React, { useEffect, useState } from "react";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import LogInMainPage from "./components/LogInMainPage";
import HomeComp from "./components/HomeComp.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CardComp from "./components/CardComp";
import TossTransaction from "./components/TossTransaction";
import Debit from "./components/Debit";
import Credit from "./components/Credit";
import KabaddiComponent from "./components/KabaddiComponent";
import CricketComponent from "./components/CricketComponent";
import ChangePassword from "./components/ChangePassword";
import CreateUserComponent from "./components/generalComponents/CreateUserComponent";
import CreateBetComponent from "./components/generalComponents/CreateBetComponent";
import UserListComponent from "./components/adminComponents/UserListComponent";
import BlockedListComponent from "./components/adminComponents/BlockedListComponent";
import MarketAnalysisComponent from "./components/adminComponents/MarketAnalysisComponent";
import EditBetComponent from "./components/adminComponents/EditBetComponent";
import SettleUpComponent from "./components/adminComponents/SettleUpComponent";
import UserAccountStatementComponent from "./components/adminComponents/UserAccountStatementComponent";
import UserPendingBetsComponent from "./components/adminComponents/UserPendingBetsComponent";
import UserBetHistoryComponent from "./components/adminComponents/UserBetHistoryComponent";
import EmployeeProtectedRoute from "./components/generalComponents/EmployeeProtectedRoute";
import ErrorComponent from "./components/generalComponents/ErrorComponent";
import RequireAutheticationRoute from "./components/generalComponents/RequireAutheticationRoute";
import FirstTimeUserProtectedRoute from "./components/generalComponents/FirstTimeUserProtectedRoute";

const TWENTY_FOUR_HRS = 24 * 60 * 60 * 1000;

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [balanceAndExposure, setBalanceAndExposure] = useState({
        balance: 0,
        exposure: 0,
    });

    useEffect(() => {
        // TODO discuss about having isAuthenticated api
        const loginTime = Date.parse(localStorage.getItem("loginTime"));
        const currentTime = new Date().valueOf();
        if (currentTime - loginTime >= TWENTY_FOUR_HRS) {
            localStorage.clear();
            setIsLoggedIn(false);
            window.location.reload();
            return;
        }
        if (localStorage.getItem("jwtToken")) setIsLoggedIn(true);
    }, [setIsLoggedIn]);

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/login"
                    element={<LogInMainPage setIsLoggedIn={setIsLoggedIn} />}
                />
                <Route
                    path="/"
                    element={
                        <RequireAutheticationRoute>
                            <HomeComp
                                isLoggedIn={isLoggedIn}
                                balanceAndExposure={balanceAndExposure}
                                setBalanceAndExposure={setBalanceAndExposure}
                            />
                        </RequireAutheticationRoute>
                    }
                >
                    <Route
                        index
                        element={
                            <FirstTimeUserProtectedRoute>
                                <CardComp
                                    isLoggedIn={isLoggedIn}
                                    setIsLoggedIn={setIsLoggedIn}
                                    balanceAndExposure={balanceAndExposure}
                                    setBalanceAndExposure={
                                        setBalanceAndExposure
                                    }
                                />
                            </FirstTimeUserProtectedRoute>
                        }
                    />
                    <Route
                        path="tossTransaction"
                        element={
                            <FirstTimeUserProtectedRoute>
                                <TossTransaction />
                            </FirstTimeUserProtectedRoute>
                        }
                    />
                    <Route
                        path="debit"
                        element={
                            <FirstTimeUserProtectedRoute>
                                <Debit />
                            </FirstTimeUserProtectedRoute>
                        }
                    />
                    <Route
                        path="credit"
                        element={
                            <FirstTimeUserProtectedRoute>
                                <Credit />
                            </FirstTimeUserProtectedRoute>
                        }
                    />
                    <Route
                        path="kabaddi"
                        element={
                            <FirstTimeUserProtectedRoute>
                                <KabaddiComponent />
                            </FirstTimeUserProtectedRoute>
                        }
                    />
                    <Route
                        path="cricket"
                        element={
                            <FirstTimeUserProtectedRoute>
                                <CricketComponent />
                            </FirstTimeUserProtectedRoute>
                        }
                    />
                    <Route path="changePassword" element={<ChangePassword />} />
                </Route>
                <Route
                    path="/admin"
                    element={
                        <RequireAutheticationRoute>
                           <HomeComp
                                isLoggedIn={isLoggedIn}
                                balanceAndExposure={balanceAndExposure}
                                setBalanceAndExposure={setBalanceAndExposure}
                            />
                        </RequireAutheticationRoute>
                    }
                >
                    <Route
                        index
                        element={
                            <FirstTimeUserProtectedRoute>
                                <CardComp isLoggedIn={isLoggedIn} />
                            </FirstTimeUserProtectedRoute>
                        }
                    />
                    <Route
                        path="createUser"
                        element={
                            <FirstTimeUserProtectedRoute>
                                <CreateUserComponent />
                            </FirstTimeUserProtectedRoute>
                        }
                    />
                    <Route
                        path="createBet"
                        element={
                            <FirstTimeUserProtectedRoute>
                                <CreateBetComponent />
                            </FirstTimeUserProtectedRoute>
                        }
                    />
                    <Route
                        path="editBet"
                        element={
                            <FirstTimeUserProtectedRoute>
                                <EditBetComponent />
                            </FirstTimeUserProtectedRoute>
                        }
                    />
                    <Route
                        path="settleUp"
                        element={
                            <FirstTimeUserProtectedRoute>
                                <SettleUpComponent />
                            </FirstTimeUserProtectedRoute>
                        }
                    />
                    <Route path="userList">
                        <Route
                            index
                            element={
                                <FirstTimeUserProtectedRoute>
                                    <UserListComponent />
                                </FirstTimeUserProtectedRoute>
                            }
                        />
                        <Route
                            path="accountStatement/:username"
                            element={<UserAccountStatementComponent />}
                        />
                        <Route />
                        <Route
                            path="pendingBets/:username"
                            element={<UserPendingBetsComponent />}
                        />
                        <Route
                            path="betHistory/:username"
                            element={<UserBetHistoryComponent />}
                        />
                    </Route>
                    <Route
                        path="blockedList"
                        element={
                            <FirstTimeUserProtectedRoute>
                                <BlockedListComponent />
                            </FirstTimeUserProtectedRoute>
                        }
                    />
                    <Route
                        path="marketAnalysis"
                        element={
                            <FirstTimeUserProtectedRoute>
                                <MarketAnalysisComponent />
                            </FirstTimeUserProtectedRoute>
                        }
                    />
                </Route>
                <Route path="*" element={<ErrorComponent />} />
            </Routes>
            <ToastContainer position="top-center" />
        </BrowserRouter>
    );
}

export default App;
