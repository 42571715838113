import React, { useState, useRef } from "react";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import { axisoReqWithAuthToken } from "../../Utils";
import axios from "axios";

export default function BetComponent({
    betId,
    potentialBettingTeam,
    setPotentialBettingTeam,
    bettingTeam,
    setBettingTeam,
    betAmount,
    setBetAmount,
    setShowBetCard,
    isBetPlaced,
    setIsBetPlaced,
    balanceAndExposure,
    rate,
    getWalletDetails,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [potentialBetAmount, setPotentialBetAmount] = useState(0);
    const [selectPredefinedValues, setSelectPredefinedValues] = useState(null);

    const preDefinedBetValues = [
        100, 500, 1000, 2500, 5000, 7500, 10000, 12500, 15000, 200000, 25000,
        50000,
    ];

    const formHandler = async (e) => {
        e.preventDefault();
        if (potentialBetAmount < 50) {
            toast.error("Betting amount must be greater than 100", {
                className: "toast-message",
            });
            betAmountInputRef.current.focus();
            return;
        }
        // make post call to client/createBet api
        try {
            setIsLoading(true);
            const { data } = await axios.get(
                process.env.REACT_APP_IPIFY_WEBSITE
            );
            const response = await axisoReqWithAuthToken.post(
                "/client/createBet",
                {
                    betId: betId,
                    amount: potentialBetAmount,
                    teamName: potentialBettingTeam,
                    ipAddress: data.ip,
                }
            );
            setBettingTeam(potentialBettingTeam);
            setBetAmount((prev) => prev + potentialBetAmount);
            setIsLoading(false);
            getWalletDetails();
            setIsBetPlaced(true);
            if (response.status === 200) {
                toast.success(
                    `Bet of amount ${potentialBetAmount} on team ${potentialBettingTeam} is placed successfully`,
                    {
                        className: "toast-message",
                    }
                );
                setShowBetCard(false);
            }
        } catch (error) {
            console.error("Error in bet creation:", error.response || error);
            toast.error("Bet creation failed!", {
                className: "toast-message",
            });
            setIsLoading(false);
            betCloseHandler();
        }
    };

    const betCloseHandler = () => {
        setShowBetCard(false);
        setPotentialBettingTeam(null);
        setSelectPredefinedValues(null);
        setPotentialBetAmount(0);
    };

    const betAmountInputRef = useRef("");

    return (
        <>
            <div className="z-10 fixed top-24 left-0 sm:left-64 w-full h-full bg-black bg-opacity-40 backdrop-blur-sm"></div>
            <div className="z-10 fixed flex items-center justify-center pr-0 top-0 left-0 text-xl w-full h-full max-lg:justify-end max-lg:pr-28 max-md:pr-8 max-sm:pr-0 max-sm:justify-center">
                <div className="bg-white rounded-lg mx-2 sm:mx-0 w-[24rem] max-sm:w-[96vw]">
                    <div className="flex items-center justify-between mb-2 px-2">
                        <div>
                            <span className="text-red-500 text-sm">
                                Balance:{" "}
                            </span>
                            <span className="justify-self-center text-sm">
                                {balanceAndExposure.balance}
                            </span>
                        </div>
                        <MdClose
                            className={
                                "text-red-800 text-xl cursor-pointer " +
                                `${isLoading && "pointer-events-none"}`
                            }
                            onClick={betCloseHandler}
                        />
                    </div>
                    <form onSubmit={formHandler}>
                        <div className="w-full grid grid-cols-4 items-center justify-between text-[1rem] max-sm:text-[0.9rem] font-bold border-b border-slate-300">
                            <span className="w-full text-center text-inherit justify-self-center px-1">
                                Bet For
                            </span>
                            <span className="w-full text-center text-inherit justify-self-center px-1">
                                Rate
                            </span>
                            <span className="w-full text-center text-inherit justify-self-center px-1">
                                Stake
                            </span>
                            <span className="w-full text-center text-inherit justify-self-center px-1">
                                Profit
                            </span>
                            <div className="col-span-full border-b border-slate-300"></div>
                            <span className="w-full text-center text-inherit justify-self-center px-1">
                                {potentialBettingTeam}
                            </span>
                            <span className="w-full text-center text-inherit justify-self-center px-1">
                                {rate}
                            </span>
                            <input
                                type="number"
                                // required
                                value={
                                    potentialBetAmount ? potentialBetAmount : ""
                                }
                                autoFocus
                                ref={betAmountInputRef}
                                className="w-full text-inherit justify-self-center bg-red-50 pl-1 outline-none"
                                onChange={(e) =>
                                    setPotentialBetAmount(+e.target.value)
                                }
                                onFocus={() => {
                                    setSelectPredefinedValues(null);
                                }}
                            />
                            <span className="w-full text-center text-inherit justify-self-center px-1">
                                {(
                                    (1 + +rate / 100) *
                                    potentialBetAmount
                                ).toFixed(2)}
                            </span>
                        </div>
                        <div className="grid grid-cols-3 gap-3 items-center justify-between bg-inherit p-2">
                            {preDefinedBetValues.map((betVal, idx) => (
                                <span
                                    key={idx}
                                    className={
                                        "transition duration-7000 ease-in-out w-full text-center border rounded-md text-[1rem] cursor-pointer hover:bg-red-400 hover:text-white " +
                                        `${
                                            selectPredefinedValues === idx
                                                ? "bg-red-400 text-white"
                                                : "bg-red-50 text-black"
                                        }`
                                    }
                                    data-value={betVal}
                                    onClick={(e) => {
                                        setPotentialBetAmount(
                                            +e.target.dataset.value
                                        );
                                        setSelectPredefinedValues(idx);
                                        betAmountInputRef.current.value = "";
                                        betAmountInputRef.current.focus();
                                    }}
                                >
                                    {betVal}
                                </span>
                            ))}
                        </div>
                        {isBetPlaced && (
                            <p className="text-sm text-center">
                                Your current bet on team{" "}
                                <span className="text-red-700">
                                    {bettingTeam}
                                </span>{" "}
                                is of amount{" "}
                                <span className="text-red-700">
                                    {betAmount}
                                </span>
                            </p>
                        )}
                        <div className="flex items-center justify-between px-4 py-2">
                            <button
                                type="reset"
                                className={
                                    "bg-white text-red-500 border border-red-500 py-1 px-2 text-sm font-light tracking-wide rounded hover:bg-red-500 hover:text-white focus:outline-none transition-btn " +
                                    `${isLoading && "pointer-events-none"}`
                                }
                                onClick={() => {
                                    setSelectPredefinedValues(null);
                                    setPotentialBetAmount(0);
                                }}
                            >
                                Reset
                            </button>
                            <button
                                type="submit"
                                className={
                                    "bg-white text-red-500 border border-red-500 py-1 px-2 text-sm font-light tracking-wide rounded hover:bg-red-500 hover:text-white focus:outline-none transition-btn " +
                                    `${isLoading && "pointer-events-none"}`
                                }
                            >
                                {isLoading ? "Sending Data..." : "Submit"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
